import React, { ReactElement, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { convertFromRaw, EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { Element, JobDescription } from './style'
import { Card, Row, Text, H4, H3, Button } from 'styles/global'
import { JobOffer } from 'types/index'

const JobOfferCard = ({ jobOffer, key }: { jobOffer: JobOffer; key: number }): ReactElement => {
  const [htmlDescription, setHtmlDescription] = useState()

  useEffect(() => {
    try {
      const content = convertFromRaw(JSON.parse(jobOffer.description))
      //@ts-ignore
      setHtmlDescription(stateToHTML(content))
    } catch {}
  }, [])

  return (
    <Card responsive="width: 95vw" padding="20px 30px" width="70vw" margin="30px 0" key={key}>
      <Row
        responsive="flex-direction: column;"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <H3
          responsive="margin-bottom: 15px;"
          ellipsis
          size="20px"
          weight="500"
          width="65%"
          margin="0 30px 0 0"
        >
          {jobOffer.officeName}
        </H3>
        <a target="_blank" rel="noreferrer" href={`mailto:${jobOffer.contact?.email}`}>
          <Button.Primary>Contacter le cabinet</Button.Primary>
        </a>
      </Row>
      <Divider style={{ margin: '20px 0 10px 0' }} />
      <Row width="100%" wrap>
        <Element>
          Type de contrat:{' '}
          <Text margin="0 0 0 7px" weight="bold">
            {jobOffer.contractType}
          </Text>
        </Element>
        <Element>
          Salaire brut mensuel:{' '}
          <Text margin="0 0 0 7px" weight="bold">
            {jobOffer.salary}
          </Text>
        </Element>
        <Element>
          Adresse:{' '}
          <Text margin="0 0 0 7px" weight="bold">
            {jobOffer.address.street}, {jobOffer.address.postalCode}, {jobOffer.address.city}
          </Text>
        </Element>
      </Row>
      <H3 color="greyBlack" ellipsis size="16px" width="65%" margin="15px 0 7px 0">
        Description du poste
      </H3>
      <JobDescription>
        {htmlDescription ? (
          <H4 dangerouslySetInnerHTML={{ __html: htmlDescription }} />
        ) : (
          <H4>{jobOffer.description}</H4>
        )}
      </JobDescription>
    </Card>
  )
}

export default JobOfferCard
