import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { CountrysSelect, RegionSelect } from 'components'
import { Button, Select } from 'styles/global'
import { Container } from './style'

const { Option } = Select

const Filter = ({
  datas,
  setDatas,
}: {
  datas: Array<any>
  setDatas: Dispatch<SetStateAction<any>>
}) => {
  const [stockDatas, setStockDatas] = useState<Array<any>>([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [contractType, setContractType] = useState('')

  useEffect(() => {
    setStockDatas(datas)
  }, [])

  const onFilter = () => {
    const newDatas = stockDatas.filter(
      (data) =>
        ((data.address.country && selectedCountry?.includes(data.address.country)) ||
          !selectedCountry) &&
        ((data.address.region && selectedRegion?.includes(data.address.region)) ||
          !selectedRegion) &&
        ((data.contractType && contractType?.includes(data.contractType)) || !contractType),
    )
    setDatas(newDatas)
  }

  return (
    <Container style={{ flexDirection: 'row' }} responsive="width: 95vw" width="70vw">
      <CountrysSelect style={{ margin: '15px' }} setSelectedCountry={setSelectedCountry} />
      <RegionSelect style={{ margin: '15px' }} setSelectedRegion={setSelectedRegion} />
      <Select
        allowClear
        style={{ margin: '15px' }}
        showSearch
        placeholder="Type de contrat (CDI / CDD)"
        onChange={(value) => setContractType(value)}
      >
        <Option value={'CDI'}>CDI</Option>
        <Option value={'CDD'}>CDD</Option>
      </Select>
      <Button.Primary margin="15px" onClick={onFilter}>
        Rechercher
      </Button.Primary>
    </Container>
  )
}

export default Filter
