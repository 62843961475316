export const regions = [
  'alsace',
  'aquitaine',
  'auvergne',
  'basse-normandie',
  'bourgogne',
  'bretagne',
  'centre',
  'champagne-ardenne',
  'corse',
  'franche-compte',
  'haute-normandie',
  'languedoc-roussillon',
  'limousin',
  'lorraine',
  'midi-pyrennees',
  'nord-pas-de-calais',
  'pays-de-la-loire',
  'picardie',
  'poitou-charentes',
  'provences-alpes-cote-dazur',
  'rhones-alpes',
  'ile-de-france',
]
