import React from 'react'

import { Container } from './style'
import bannerImg from 'assets/illustrations/banner2.png'
import onemedImg from 'assets/illustrations/onemed_logo.png'
import { Column, Row } from 'styles/global'

const HunterService = () => {
  return (
    <Container>
      <Row>
        <img src={onemedImg} />
        <Column></Column>
      </Row>
    </Container>
  )
}

export default HunterService
