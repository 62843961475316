import React from 'react'
import { Helmet } from 'react-helmet'

import { Container, Content } from './style'
import BlogCard from './BlogCard'
import { routes } from 'router'

import imgArticle1 from 'assets/blog/article1.png'

const HomeBlog = () => {
  return (
    <Container>
      <Helmet>
        <title>Découvrez les différents articles de blogs emploi-asv</title>
        <meta name="description" content="Description SEO de l'article" />
        <meta name="keywords" content="asv, emploi, vétérinaire" />
      </Helmet>
      <Content>
        <BlogCard img={imgArticle1} title="Titre article 1" path={routes.article1.path} />
      </Content>
    </Container>
  )
}

export default HomeBlog
