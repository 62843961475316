import { styled, theme, mediaQuery } from 'styles'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0px 15px;
  border: #dedede 1px solid;
  border-radius: 7px;
  background-color: #fcfcfc;
  position: relative;
  flex-wrap: wrap;
  ${mediaQuery.mobile} {
    max-width: 95%;
  }
`
