import { styled, theme } from 'styles'
import { Column, Text } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const Content = styled(Column)`
  width: 70vw;
  height: auto;
  display: flex;
  jsutify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  margin-top: 40px;
`

export const BlogCard = styled.div`
  width: 300px;
  height: 210px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 2px 2px 4px ${theme.color.grey};
  margin: 30px;
  cursor: pointer;
`

export const ImgBlogCard = styled.img`
  width: 100%;
  height: auto;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const CardTitle = styled(Text)`
  font-size: 16px;
  margin-top: 10px;
`
