import React from 'react'
import { Skeleton } from './style'

const Skeletons = () => {
  return (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  )
}

export default Skeletons
