import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { CreateJobOfferForm, Loader } from 'components'
import { urlParams } from 'utils'
import { message } from 'antd'
import { jobOfferService } from 'services'

const CreateJobOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  // CHECK SUBSCRIPTION
  useEffect(() => {
    const checkoutSessionId = urlParams()?.checkoutSessionId
    if (checkoutSessionId) {
      setIsLoading(true)
      if (checkoutSessionId === 'failed') {
        message.error('An error occurred with the payment, please try again or contact us')
        setIsLoading(false)
      } else {
        jobOfferService.validPayment({ sessionId: checkoutSessionId }).then((result) => {
          window.location.pathname = routes.viewOffers.path
          setIsLoading(false)
        })
      }
    }
  }, [])

  return (
    <Container>
      <Helmet>
        <title>Partagez vos offres d’emploi vétérinaire et obtenez de la visibilité</title>
        <meta
          name="description"
          content="Publiez votre offre d'emploi vétérinaire, trouvez le profil idéale en obtenant un maximum de visibilité sur notre jobBoard et notre page Facebook n°1 en France."
        />
        <meta name="keywords" content="asv, emploi, vétérinaire" />
      </Helmet>
      <Loader isDisplayed={isLoading} />
      <CreateJobOfferForm setIsLoading={setIsLoading} />
    </Container>
  )
}

export default CreateJobOffer
