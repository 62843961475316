import React, { Dispatch, SetStateAction } from 'react'

import { Select } from 'styles/global'
import { countrys } from 'datas/countrys'

const { Option } = Select

type Props = {
  setSelectedCountry: any
  style?: any
}

const CountrysSelect = ({ setSelectedCountry, style }: Props) => {
  return (
    <Select
      allowClear
      showSearch
      width="150px"
      style={style}
      placeholder="Pays"
      optionFilterProp="children"
      onChange={(value) => setSelectedCountry(value)}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value={''}>Tous les pays</Option>
      {countrys.map((country, index) => (
        <Option key={index} value={country.name}>
          {country.name}
        </Option>
      ))}
    </Select>
  )
}

export default CountrysSelect
