import { RoutesType } from 'types'
import { ROLES } from 'constants/index'

const routes: RoutesType = {
  login: {
    path: '/login',
    name: 'login',
    access: '*',
    haveMenu: false,
  },
  home: {
    path: '/',
    name: 'home',
    access: '*',
    haveMenu: true,
  },
  createOffer: {
    path: '/creer-une-offre-emploi-veterinaire',
    name: 'createOffer',
    access: '*',
    haveMenu: true,
  },
  viewOffers: {
    path: '/decouvrir-offres-emploi-veterinaire',
    name: 'viewOffers',
    access: '*',
    haveMenu: true,
  },
  hunterService: {
    path: '/nos-services-de-chasse',
    name: 'hunterService',
    access: '*',
    haveMenu: true,
  },
  CGV: {
    path: '/conditions-generales-de-vente',
    name: 'cgv',
    access: '*',
    haveMenu: true,
  },
  CGU: {
    path: '/conditions-generales-utilisation',
    name: 'cgu',
    access: '*',
    haveMenu: true,
  },
  blogHome: {
    path: '/blog',
    name: 'blog',
    access: '*',
    haveMenu: true,
  },

  // BLOG PAGES

  article1: {
    path: '/exemple-url-article-1',
    name: 'article1',
    access: '*',
    haveMenu: true,
  },
}

export default routes
