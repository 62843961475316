import { styled, theme, mediaQuery } from 'styles'
import { Row, Text } from 'styles/global'

export const HeaderContainer = styled(Row)`
  width: 100vw;
  height: 60px;
  background-color: ${theme.color.primary};
  padding-left: 120px;
  padding-right: 120px;
  align-items: center;
  ${mediaQuery.mobile} {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`

export const MenuItem = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    `
    font-weight: 500;
    text-decoration: underline;
    cursor: initial;
    `};
`
