import React, { ReactElement } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import PageWrapper, {
  Login,
  Home,
  CreateJobOffer,
  JobOfferList,
  HunterService,
  BlogHome,
  Article1,
  CGV,
  CGU,
} from 'pages'
import routes from 'router/routes'

function Router(): ReactElement {
  return (
    <BrowserRouter>
      <PageWrapper>
        <Routes>
          <Route path={routes.home.path} element={<Home />} />
          <Route path={routes.createOffer.path} element={<CreateJobOffer />} />
          <Route path={routes.viewOffers.path} element={<JobOfferList />} />
          <Route path={routes.hunterService.path} element={<HunterService />} />
          <Route path={routes.blogHome.path} element={<BlogHome />} />
          <Route path={routes.CGU.path} element={<CGU />} />
          <Route path={routes.CGV.path} element={<CGV />} />

          {/* BLOG PAGES */}
          <Route path={routes.article1.path} element={<Article1 />} />

          <Route path="" element={<Home />} />
        </Routes>
      </PageWrapper>
    </BrowserRouter>
  )
}

export { routes }
export default Router
