import { styled, theme } from 'styles'
import { Column, Text } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const Content = styled(Column)`
  width: 70vw;
  height: auto;
  display: flex;
  jsutify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  margin-top: 40px;
`
