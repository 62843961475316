import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { UserContext } from 'providers'
import { routes } from 'router'
import { HeaderContainer, MenuItem } from './style'
import { Text } from 'styles/global'

import { Link, useLocation } from 'react-router-dom'

type Props = {
  isMobile: boolean
  setIsMobileMenuDisplayed: Dispatch<SetStateAction<boolean>>
}

const Header = ({ isMobile, setIsMobileMenuDisplayed }: Props) => {
  const { t } = useTranslation()
  const { user, resetUser } = useContext(UserContext)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState('')
  const [isFixedNavbar, setIsFixednavbar] = useState(false)

  const location = useLocation()

  window.onscroll = function (e) {
    if (!isFixedNavbar && window.scrollY > 65) {
      setIsFixednavbar(true)
    } else if (window.scrollY < 65) {
      setIsFixednavbar(false)
    }
  }

  useEffect(() => {
    setIsMobileMenuOpen(false)
    if (location.pathname === routes.home.path) {
      setCurrentPage('1')
    } else if (location.pathname === routes.viewOffers.path) {
      setCurrentPage('2')
    } else if (location.pathname === routes.createOffer.path) {
      setCurrentPage('3')
    } else if (location.pathname === routes.hunterService.path) {
      setCurrentPage('4')
    }
  }, [location])

  return isMobile ? (
    <>
      <HeaderContainer>
        <MenuOutlined
          style={{ fontSize: '22px', color: 'white' }}
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </HeaderContainer>
      <Drawer
        className="blue-back"
        title="Emploi-asv.scom"
        placement="left"
        onClose={() => setIsMobileMenuOpen(false)}
        visible={isMobileMenuOpen}
      >
        <Link style={{ marginRight: '50px' }} to={routes.home.path}>
          <MenuItem isActive={window.location.pathname === routes.home.path}>Accueil</MenuItem>
        </Link>
        <Link style={{ marginRight: '50px' }} to={routes.createOffer.path}>
          <MenuItem isActive={window.location.pathname === routes.createOffer.path}>
            Déposer une offre d&#39;emploi
          </MenuItem>
        </Link>
        <a
          target="blank"
          rel="noopener"
          style={{ marginRight: '50px' }}
          href="https://onemedrecrutement.com/fr/"
        >
          <MenuItem>Service de chassse</MenuItem>
        </a>
        <Link to={routes.viewOffers.path}>
          <MenuItem isActive={window.location.pathname === routes.viewOffers.path}>
            Voir les offres d’emplois
          </MenuItem>
        </Link>
      </Drawer>
    </>
  ) : (
    <Menu
      style={
        isFixedNavbar
          ? {
              position: 'fixed',
              top: '0',
              zIndex: '100',
              width: '100vw',
            }
          : {}
      }
      key={currentPage}
      selectedKeys={[currentPage]}
      mode="horizontal"
      className="header"
    >
      <Menu.Item key="1">
        <Link style={{ fontWeight: '600' }} to={routes.home.path}>
          Accueil
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link style={{ fontWeight: '600' }} to={routes.viewOffers.path}>
          Voir les offres d’emplois
        </Link>
      </Menu.Item>
      <Menu.SubMenu key="SubMenu" title="Services aux entreprises">
        <Menu.Item key="3">
          <Link style={{ marginRight: '50px' }} to={routes.createOffer.path}>
            Déposer une offre d&#39;emploi
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <a
            target="blank"
            rel="noopener"
            style={{ marginRight: '50px' }}
            href="https://onemedrecrutement.com/fr/"
          >
            Service de chassse
          </a>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
}

export default Header
