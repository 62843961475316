import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { message, Radio, Space } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Form, Button, Text, Input, Title } from 'styles/global'
import { JobOffer } from 'types'
import { jobOfferService } from 'services'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { CountrysSelect, RegionSelect } from 'components'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
let stripe
stripePromise.then((resStripe) => (stripe = resStripe))

const CreateJobOffer = ({ setIsLoading }: { setIsLoading?: Dispatch<SetStateAction<boolean>> }) => {
  const [jobOffer, setJobOffer] = useState<JobOffer>({
    officeName: '',
    jobType: '',
    contractType: '',
    description: '',
    salary: '',
    startDate: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
      region: '',
    },
    contact: {
      email: '',
    },
  })
  const [startSalary, setStartSalary] = useState<string>('')
  const [endSalary, setEndSalary] = useState<string>('')
  const [isNotSpecificSalary, setIsNotSpecificSalary] = useState<boolean>(false)
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

  const editor = React.useRef(null)

  const onObjectChange = (attr1: string, attr2: string, value: string) => {
    setJobOffer({ ...jobOffer, [attr1]: { ...jobOffer[attr1], [attr2]: value } })
  }

  const handleChange = (attr, value) => {
    setJobOffer({ ...jobOffer, [attr]: value })
  }

  const onFinish = (values: any) => {
    if (setIsLoading) {
      setIsLoading(true)
    }
    const describeContent = editorState.getCurrentContent()
    const description = JSON.stringify(convertToRaw(describeContent))
    const salary = isNotSpecificSalary ? 'En fonction du profil' : `${startSalary}€ - ${endSalary}€`
    jobOfferService.create({ ...jobOffer, salary, description }).then(({ sessionId }) => {
      if (sessionId) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        if (setIsLoading) {
          setIsLoading(false)
        }
        message.error('Une erreur est servenue, veuillez nous contacter')
      }
    })
  }

  return (
    <Form
      responsive="width: 95vw; max-width: 450px;"
      width="450px"
      layout="vertical"
      onFinish={onFinish}
      isStyled
    >
      <Title margin="0 0 30px 0" color="secondary">
        Déposer une offre d’emploi
      </Title>
      <Form.Item
        name="officeName"
        onChange={(e) => handleChange('officeName', e.target.value)}
        label="Nom de la clinique"
        rules={[{ required: true, message: 'Veuillez renseigner votre nom de cabinet' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Text margin="18px 0 10px 0" size="16px">
        Adresse
      </Text>

      <Form.Item
        name="Pays"
        label="Pays"
        rules={[
          { required: !jobOffer.address?.country, message: 'Veuillez renseigner votre pays' },
        ]}
      >
        <CountrysSelect
          setSelectedCountry={(value) => onObjectChange('address', 'country', value)}
        />
      </Form.Item>
      <Form.Item
        name="Région"
        label="Région"
        rules={[
          { required: !jobOffer.address?.region, message: 'Veuillez renseigner votre région' },
        ]}
      >
        <RegionSelect setSelectedRegion={(value) => onObjectChange('address', 'region', value)} />
      </Form.Item>
      <Form.Item
        name="street"
        onChange={(e) => onObjectChange('address', 'street', e.target.value)}
        label="Rue et numéro de rue"
        rules={[{ required: true, message: 'Veuillez renseigner votre rue et numéro de rue' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Form.Item
        name="city"
        onChange={(e) => onObjectChange('address', 'city', e.target.value)}
        label="Ville"
        rules={[{ required: true, message: 'Veuillez renseigner votre ville' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Form.Item
        name="postalCode"
        onChange={(e) => onObjectChange('address', 'postalCode', e.target.value)}
        label="Code postal"
        rules={[{ required: true, message: 'Veuillez renseigner votre code postal' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Text margin="18px 0 10px 0" size="16px">
        Contact
      </Text>
      <Form.Item
        name="email"
        onChange={(e) => onObjectChange('contact', 'email', e.target.value)}
        label="Adresse e-mail"
        rules={[
          { required: true, message: 'Veuillez renseigner votre adresse email' },
          {
            type: 'email',
            message: "Votre eadresse mail n'est pas valide",
          },
        ]}
      >
        <Input width="100%" />
      </Form.Item>
      {/*
      <Form.Item
        name="jobType"
        onChange={(e) => handleChange('jobType', e.target.value)}
        label="Intitulé du poste"
        rules={[{ required: true, message: 'Veuillez renseigner le type de poste' }]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={'Assistant vétérinaire'}>Assistant vétérinaire</Radio>
            <Radio value={'Vétérinaire'}>Vétérinaire</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      */}
      <Form.Item
        name="description"
        onChange={(e) => handleChange('description', e.target.value)}
        label="Description du poste"
        rules={[{ required: true, message: 'Veuillez renseigner la description du poste' }]}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(editorState) => setEditorState(editorState)}
          toolbar={{
            options: [
              'inline',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'image',
              'remove',
              'history',
            ],
          }}
        />
      </Form.Item>
      <Form.Item
        name="contractType"
        onChange={(e) => handleChange('contractType', e.target.value)}
        label="Type de contrat"
        rules={[{ required: true, message: 'Veuillez renseigner le type de contrat' }]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={'CDI'}>CDI</Radio>
            <Radio value={'CDD'}>CDD</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="salary"
        onChange={(e) => handleChange('salary', e.target.value)}
        label="Salaire proposé (brut mensuel)"
        rules={[
          {
            required: !(startSalary || endSalary || isNotSpecificSalary === true),
            message: 'Veuillez renseigner le salaire mensuel brut',
          },
        ]}
      >
        <Input.Group style={{ alignItems: 'center', display: 'flex' }} compact>
          <Input
            disabled={isNotSpecificSalary}
            onChange={(e) => setStartSalary(e.target.value)}
            style={{ width: '100px', marginRight: '10px' }}
            autosize={false}
            suffix="€"
          />
          à
          <Input
            disabled={isNotSpecificSalary}
            onChange={(e) => setEndSalary(e.target.value)}
            style={{ width: '100px', marginLeft: '10px' }}
            autosize={false}
            suffix="€"
          />
        </Input.Group>
        <Checkbox
          style={{ marginTop: '7px' }}
          onChange={(e) => setIsNotSpecificSalary(e.target.checked)}
        >
          En fonction du profil
        </Checkbox>
      </Form.Item>
      <Text margin="18px 0 10px 0" size="16px">
        Prix de la publication: 60€
      </Text>
      <Form.Item>
        <Button.Primary width="100%" type="primary" htmlType="submit">
          Payer et publier mon offre
        </Button.Primary>
      </Form.Item>
    </Form>
  )
}

export default CreateJobOffer
