import React from 'react'
import { Helmet } from 'react-helmet'

import { Container, Content } from './style'
import { Title, Text, H2, H3, H4 } from 'styles/global'

const PageBlog = () => {
  return (
    <Container>
      <Helmet>
        <title>Titre SEO de l&apos;article</title>
        <meta name="description" content="Description SEO de l'article" />
        <meta name="keywords" content="asv, emploi, vétérinaire" />
      </Helmet>
      <Content>
        <Title style={{ marginBottom: '30px' }}>Titre de l&apos;article</Title>
        <H2>Sous titre</H2>
        <H3>Sous titre</H3>
        <H4>Sous titre</H4>
        <Text>
          Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre
          provisoire pour calibrer une mise en page, le texte définitif venant remplacer le
          faux-texte dès qu&apos;il est prêt ou que la mise en page est achevée. Généralement, on
          utilise un texte en faux latin, le Lorem ipsum ou Lipsum.
        </Text>
      </Content>
    </Container>
  )
}

export default PageBlog
