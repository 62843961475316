import React, { ReactElement, useState, useEffect, useContext } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'

import { Header, Footer } from 'components'
import { windowSizes } from 'styles/mediaQuery'
import { routes } from 'router'
import { styled, mediaQuery, theme } from 'styles'
import { Route } from 'types'
import { authUtils, homeRouteUtils } from 'utils'
import { UserContext } from 'providers'
import { useAuth } from 'hooks'

interface Props {
  children: ReactElement
}

export const Wrapper = styled.div`
  width: 100%;
  background: ${theme.color.white};
  display: flex;
  flex-direction: ${({ direction }: { direction: string }) => direction};
  padding-bottom: ${({ paddingBottom }: { paddingBottom: string }) => paddingBottom};
`

const PageWrapper = ({ children }: Props): ReactElement => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user, isAuthenticated } = useContext(UserContext)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < windowSizes.mobile)
  const [isMobileMenuDisplayed, setIsMobileMenuDisplayed] = useState<boolean>(false)
  const { getMyInformations } = useAuth()

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  useEffect(() => {
    getMyInformations()
  }, [])

  const currentRouteKey: string | undefined = Object.keys(routes).find(
    (key) => routes[key].path === pathname,
  )

  const currentRoute: Route | undefined =
    currentRouteKey !== undefined ? routes[currentRouteKey] : undefined

  const isAuthorizedCurrentRoute: boolean = Object.keys(routes).some(
    (key) => routes[key].access.includes(authUtils.currentRole()) || routes[key].access === '*',
  )
  if (!isAuthorizedCurrentRoute) {
    return <Navigate to={homeRouteUtils(user.role)} />
  }
  if (currentRoute?.haveMenu) {
    return (
      <Wrapper direction="column">
        <Header isMobile={isMobile} setIsMobileMenuDisplayed={setIsMobileMenuDisplayed} />
        <Wrapper paddingBottom="80px" direction="row">
          {/*
          <Menu
            isMobile={isMobile}
            setIsMobileMenuDisplayed={setIsMobileMenuDisplayed}
            isMobileMenuDisplayed={isMobileMenuDisplayed}
          />*/}
          {children}
        </Wrapper>
        <Footer />
      </Wrapper>
    )
  }
  return <Wrapper paddingBottom="80px">{children}</Wrapper>
}

export default PageWrapper
export { default as Login } from 'pages/login'
export { default as Home } from 'pages/home'
export { default as CreateJobOffer } from 'pages/createJobOffer'
export { default as JobOfferList } from 'pages/jobOfferList'
export { default as HunterService } from 'pages/hunterService'
export { default as CGU } from 'pages/legal/CGU'
export { default as CGV } from 'pages/legal/CGV'
export { default as BlogHome } from 'pages/blog'

// BLOG PAGES
export { default as Article1 } from 'pages/blog/ExempleArticle'
