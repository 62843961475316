import { styled, theme } from 'styles'

export const Element = styled.div`
  background: ${theme.color.grey};
  border-radius: 6px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  margin: 10px;
`

export const JobDescription = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
`
