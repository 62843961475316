import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { UserContext } from 'providers'
import { routes } from 'router'
import { FooterContainer, FooterItem, FooterTitle, FooterExternalLink } from './style'
import { Column, Text, Title } from 'styles/global'

import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
  return (
    <FooterContainer>
      <Column margin="0 60px 30px 0" width="260px">
        <FooterTitle>Espace vétérinaire</FooterTitle>
        <FooterItem to={routes.viewOffers.path}>Consulter les offres d&apos;emploi</FooterItem>
      </Column>
      <Column margin="0 60px 30px 0" width="260px">
        <FooterTitle>Espace cabinet</FooterTitle>
        <FooterItem to={routes.createOffer.path}>Déposer une offre d&apos;emploi</FooterItem>
        <FooterExternalLink target="blank" rel="noopener" href="https://onemedrecrutement.com/fr/">
          Service de chasse
        </FooterExternalLink>
      </Column>
      <Column margin="0 60px 30px 0" width="260px">
        <FooterTitle>Legal</FooterTitle>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGU.path}>
          Conditions générales d&apos;utilisation
        </FooterItem>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGV.path}>
          Conditions générales de vente
        </FooterItem>
      </Column>
    </FooterContainer>
  )
}

export default Footer
