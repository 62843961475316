import { JobOfferServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const login: JobOfferServices = {
  create: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'create' })
  },
  validPayment: async ({ sessionId }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/valid-payment`, {
      method: 'POST',
      body: JSON.stringify({ sessionId }),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'validPayment' })
  },
  find: async () => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  findOne: async ({ jobOfferId }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/${jobOfferId}`, {
      method: 'POST',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
}

export default login
