import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container, BlogCard, ImgBlogCard, CardTitle, Content } from './style'

import imgArticle1 from 'assets/blog/article1.png'

type Props = {
  path: string
  img: any
  title: string
}

const BlogCardComponent = ({ path, img, title }: Props) => {
  return (
    <Link to={path}>
      <BlogCard>
        <ImgBlogCard src={img} />
        <CardTitle>{title}</CardTitle>
      </BlogCard>
    </Link>
  )
}

export default BlogCardComponent
