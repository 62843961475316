import { Link } from 'react-router-dom'
import { styled, theme } from 'styles'
import { Row, Text } from 'styles/global'

export const FooterContainer = styled(Row)`
  width: 100vw;
  min-height: 100px;
  background-color: ${theme.color.primary};
  color: white;
  padding: 30px;
  padding-bottom: 0;
  padding-left: 14vw;
  padding-right: 14vw;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FooterItem = styled(Link)`
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 7px;
  &:hover {
    text-decoration: underline;
    color: white;
  }
`

export const FooterExternalLink = styled.a`
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 7px;
  &:hover {
    text-decoration: underline;
    color: white;
  }
`

export const FooterTitle = styled(Text)`
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`
