import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Divider } from 'antd'
import { Helmet } from 'react-helmet'

import { Container, Banner, Icon } from './style'
import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { windowSizes } from 'styles/mediaQuery'

import shareOfferImg from 'assets/icons/share_offer.png'
import getOfferImg from 'assets/icons/get_offer.png'
import facebookImg from 'assets/icons/facebook.png'

const Home = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < windowSizes.mobile)

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  return (
    <Container>
      <Helmet>
        <title>
          Partagez vos offres d’emploi assistant vétérinaire et obtenez de la visibilité
        </title>
        <meta
          name="description"
          content="Publiez votre offre d'emploi assistant vétérinaire, trouvez le profil idéale en obtenant un maximum de visibilité sur notre jobBoard et notre page Facebook n°1 en France."
        />
        <meta name="keywords" content="asv, emploi, vétérinaire" />
      </Helmet>
      <Banner>
        <H3 style={{ maxWidth: '90vw' }} weight="700" size="35px" color="white">
          Emploi-asv.com
        </H3>
      </Banner>
      <Row
        responsive="flex-direction: column; align-items: center;"
        margin="60px 0 30px 0"
        width="70vw"
      >
        <Icon src={shareOfferImg} />
        <Column responsive="align-items: center; width: 90vw;">
          <H1
            responsive="margin: 20px 0;"
            weight="500"
            size="33px"
            position="start"
            margin="0 0 8px 0"
            color="secondary"
          >
            Partagez vos offres d’emploi auxiliaire vétérinaire et obtenez de la visibilité
          </H1>
          <Text margin="0 0 18px 0">
            Partagez votre offre d&apos;emploi ASV en moins de 5 minutes. Recevez des candidatures
            rapidement sur votre boite mail. Votre annonce sera également partagée sur la{' '}
            <a href="https://www.facebook.com/emploiasv" target="_blank" rel="noopener noreferrer">
              page Facebook
            </a>{' '}
            Emploi Job ASV qui compte plus de 14000 abonnées.
          </Text>
          <Link to={routes.createOffer.path}>
            <Button.Primary fontSize="20px">Partager une offre</Button.Primary>
          </Link>
        </Column>
      </Row>
      <Divider />
      <Row
        responsive="flex-direction: column; align-items: center; margin: 0;"
        margin="30px 30px 0 0"
        width="70vw"
      >
        <Column
          responsive="align-items: center; margin-bottom: 40px; width: 90vw;"
          alignItems="flex-end"
        >
          <H2
            responsive="text-align: start; margin: 20px 0;"
            weight="500"
            size="33px"
            position="end"
            margin="0 0 8px 0"
            color="secondary"
          >
            Retrouvez les meilleures offres d’emploi ASV
          </H2>
          <Text responsive="text-align: start;" position="end" margin="0 0 18px 0">
            Vous êtes ASV et vous recherchez votre futur poste. Retrouvez les offres des cliniques
            qui recrutent près de chez vous. Enfin un site dédié aux ASV en recherche d&apos;emploi.
          </Text>
          <Link to={routes.viewOffers.path}>
            <Button.Primary fontSize="20px">Découvrir les offres</Button.Primary>
          </Link>
        </Column>
        <Icon src={getOfferImg} />
      </Row>

      <H4 weight="500" size="24px" margin="70px 0 10px 0" color="secondary">
        QUI SOMMES NOUS
      </H4>
      <Text style={{ maxWidth: '90vw' }} size="16px">
        L&apos;équipe Emploi ASV a été créée dans le but de rendre accessible une multitude
        d&apos;offres d&apos;emploi ASV à toutes les ASV en recherche d&apos;emploi.
      </Text>

      <Text weight="500" size="24px" margin="50px 0 10px 0" color="secondary">
        CONTACT
      </Text>
      <Text margin="5px" weight="500" size="16px">
        Téléphone: 06 51 18 75 01
      </Text>
      <Text margin="5px" weight="500" size="16px">
        Adresse e-mail: asv.emploi@gmail.com
      </Text>
      <Text margin="5px" weight="500" size="16px">
        Facebook:
        <a href="https://www.facebook.com/emploiasv" target="_blank" rel="noopener noreferrer">
          <img src={facebookImg} style={{ height: '20px', marginLeft: '10px' }} />
        </a>
      </Text>
    </Container>
  )
}

export default Home
