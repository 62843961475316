import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { H1, Input, Row, Title } from 'styles/global'
import { Skeletons, JobOfferCard, Filter } from 'components'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { JobOffer } from 'types'

const CreateJobOffer = () => {
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])

  const {
    isLoading,
    data: jobOffers,
    isSuccess: isDatasLoad,
  } = useQuery('jobOffers', () =>
    jobOfferService.find().then((res) => {
      setDisplayedJobOffers(res.data.reverse())
      return res.data
    }),
  )

  return (
    <Container>
      <Helmet>
        <title>La liste des meilleurs offres d&apos;emploi asv en France</title>
        <meta
          name="description"
          content="Retrouver les différentes annonces de recherche assistant vétérinaire publiées par des cabinets professionnels. Recherche par région, tranche de salaire et description de poste."
        />
        <meta name="keywords" content="asv, emploi, vétérinaire" />
      </Helmet>
      <H1
        size="30px"
        style={{ color: '#0875BD', marginTop: '30px', maxWidth: '90vw', marginBottom: '30px' }}
        weight="bold"
      >
        Liste des offres d&apos;emploi asv
      </H1>
      <Row
        style={{ marginTop: '25px' }}
        responsive="width: 95vw"
        padding="20px 30px"
        width="70vw"
        jusitfyContent="flex-start"
      >
        {(displayedJobOffers.length || isDatasLoad) && (
          <Filter setDatas={setDisplayedJobOffers} datas={jobOffers || []} />
        )}
      </Row>
      {isLoading ? (
        <Skeletons />
      ) : displayedJobOffers.length ? (
        displayedJobOffers?.map((jobOffer, index) => (
          <JobOfferCard key={index} jobOffer={jobOffer} />
        ))
      ) : (
        <Title margin="30px 0 0 0">Aucun résultats</Title>
      )}
    </Container>
  )
}

export default CreateJobOffer
