import { styled, mediaQuery, theme } from 'styles'
import { Column, Text } from 'styles/global'
import banner from 'assets/illustrations/banner.png'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const Banner = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
`
export const Icon = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 30px;
`
